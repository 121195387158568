import { Action, createReducer, on } from '@ngrx/store';
import { OrderType } from '@proman/interfaces/entity-interfaces';
import { setOrderTypes } from './orders.actions';

export interface OrdersState {
    data: {
        orderTypes?: OrderType[];
    };
}

export const initialState: OrdersState = {
    data: {},
};

export const ordersReducer = createReducer(
    initialState,
    on(setOrderTypes, (state: OrdersState, action) => {
        return {  ...state, data: { ...state.data, orderTypes: action.payload } }
    })
);

export function reducer(state: OrdersState, action: Action): OrdersState {
    return ordersReducer(state, action);
}
