import { createAction, props } from '@ngrx/store';
import { OrderType } from '@proman/interfaces/entity-interfaces';

export enum OrdersActions {
    SetOrderTypes = `[Orders] Set Order Types`,
}

export const setOrderTypes = createAction(
    OrdersActions.SetOrderTypes,
    props<{ payload: OrderType[] }>()
);
