import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrdersState } from './orders.reducer';

export const ordersKey: string = 'orders';

export const selectOrdersState = createFeatureSelector<OrdersState>(ordersKey);

export const getOrderTypes = createSelector(
    selectOrdersState, (state: OrdersState) => state.data.orderTypes
);
